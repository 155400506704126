
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$argon-dashboard-angular-primary: mat-palette($mat-indigo);
$argon-dashboard-angular-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$argon-dashboard-angular-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$argon-dashboard-angular-theme: mat-light-theme($argon-dashboard-angular-primary, $argon-dashboard-angular-accent, $argon-dashboard-angular-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($argon-dashboard-angular-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.my-panel .mat-dialog-container {
  // overflow: hidden;
  // border-radius: 5px;
  // background: transparent !important;
  // background-color: var(--background);
  box-shadow: none;
  background: rgba(0, 0, 0, 0.0);

  // padding: 5px;

}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #0072ff;
  }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}
